import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@et/environment';

Sentry.init({
  dsn: environment.sentryElsiDns,
  environment: environment.environment,
  enabled: environment.production,
  autoSessionTracking: true,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        'localhost',
        environment.docPrepService.domain,
        environment.apiServer.domain,
        environment.docio.domain,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.environment === 'prod' ? 1.0 : 0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
