<router-outlet></router-outlet>
<elsi-footer></elsi-footer>
<et-notifications-list></et-notifications-list>

<div
  *ngIf="isLoading"
  class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
>
  <et-atoms-spinner></et-atoms-spinner>
</div>
