import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'elsi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'elsi';

  isLoading = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.displayLoaderOnLinkNavigation(event);
      } else if (event instanceof NavigationEnd) {
        this.isLoading = false;
      }
    });
  }

  /**
   * This method is used to display loader on link navigation
   * before the signing room is loaded
   * @param event - NavigationStart event
   */
  private displayLoaderOnLinkNavigation(event: NavigationStart) {
    const regex =
      /^\/link\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
    if (regex.test(event.url)) {
      this.isLoading = true;
    }
  }
}
