import { NgModule } from '@angular/core';
import { environment } from '@et/environment';

import {
  packetsApiModule,
  Configuration as PacketsConfiguration,
  ConfigurationParameters as PacketsConfigurationParameters,
} from '@escrowtab/packets-api-client';

function packetsConfigFactory(): PacketsConfiguration {
  const params: PacketsConfigurationParameters = {
    basePath: environment.packetService.domain,
  };
  return new PacketsConfiguration(params);
}

@NgModule({
  imports: [packetsApiModule.forRoot(packetsConfigFactory)],
  exports: [packetsApiModule],
})
export class AppApiModule {}
