import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { ApiModule } from '@et/api';
import { environment } from '@et/environment';
import { HttpClientModule } from '@angular/common/http';
import { NotificationsModule } from '@et/notifications';
import { AppApiModule } from './app-api.module';
import { Router } from '@angular/router';
import { ErrorLoggerModule, GlobalErrorHandler } from '@et/error-logger';
import * as Sentry from '@sentry/angular-ivy';
import { PresentationalAtomsModule } from '@et/presentational/atoms';

@NgModule({
  declarations: [AppComponent, HomeComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotificationsModule,
    HttpClientModule,
    AppApiModule,
    ApiModule.forRoot({
      rootUrl: `${environment.apiServer.domain}`,
    }),
    NgxsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    ErrorLoggerModule,
    PresentationalAtomsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
